body {
  font-family: Kanit;
  background-color: #0b0500;
}

.App {
  margin: 0;
  background-color: #0B2434;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;  
}

main {  
  margin: auto;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #000000;
  /* height:450px; */
  max-width:1200px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
}

.title {
  color: #fe5e41 ;
  font-size: 3rem;
  margin: 0;
  margin-bottom: 24px;
  font-weight: 700;
}

.theChart {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 24px;
}

.chartTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading{
  font-size: 1.5rem;
  font-weight: 700;
}

.summary {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  display: grid;
  grid-template: auto auto / repeat(2, 1fr);
  gap: 12px;
}

.displayBox {
  background-color: #f3c178;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.displayBoxText{
  margin-bottom:0px;
}

.displayBoxValue{
  margin-top:6px;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 12px;
}

.green {
  color:#00a878;
}

.red {
  color: #fe5e41;
}

.dataPoints {
  width:50%;
  vertical-align: bottom;
  text-align: end;
}

input, .react-datetime-picker__wrapper {
  border-radius: 5px;
  padding:3px;
}

.copyright {
  margin: 0px;
  width:100%;
  text-align: right;
}

.copyright > a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 720px) {
  .dates {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary {
    display: grid;
    grid-template: auto auto / repeat(2, 1fr);
    gap: 12px;
  }

  .summary:last-child {
    grid-row: 1 / 2;
  }

  .displayBox {
    width: 100%;
    margin-bottom: 12px;;
  }

  .dataPoints {
    width:100%;
    vertical-align: bottom;
    text-align: end;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .summary {
    display: flex;
    flex-direction: column;
  }

  .displayBox {
    width: 100%;
    margin-bottom: 12px;;
  }

  
}